import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { WarningMessage } from "@oriola-origo/origo-ui-core";
import AutoComplete from "../../../generic/autoComplete/autoComplete";
// eslint-disable-next-line import/no-cycle
import { fetchProducts, clearProducts } from "../../../../redux";
import { formatProductCompact } from "../../../../utils/productUtil/productUtil";
import getProductWarnings from "./getProductWarnings";

function ProductSearch({
  onSelect,
  error,
  product,
  id,
  caseType,
  productReturn,
  disabled,
}) {
  const { products, fetching } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    dispatch(clearProducts());
  }, [dispatch]);

  useEffect(() => {
    const newWarnings = getProductWarnings(t, product, caseType, productReturn);
    setWarnings(newWarnings);
  }, [t, product, caseType, productReturn]);

  const fetchProductsOnChange = queryText => {
    dispatch(fetchProducts(0, 20, queryText));
  };

  const handleProductSelect = selectedProduct => {
    const newWarnings = getProductWarnings(
      t,
      selectedProduct,
      caseType,
      productReturn
    );
    setWarnings(newWarnings);
    onSelect(selectedProduct);
  };

  const getOptionRow = productOption =>
    formatProductCompact(productOption, i18n.language, t);

  return (
    <Box>
      <AutoComplete
        error={!!error}
        value={product.materialId ? product : null}
        onChange={fetchProductsOnChange}
        options={products}
        getOptionName={getOptionRow}
        onSelect={handleProductSelect}
        placeholder={t("productSearchPlaceholder")}
        id={`${id}-search`}
        fetching={fetching}
        disabled={disabled}
      />
      {warnings.map(warning => (
        <WarningMessage
          key={warning.id}
          id={warning.id}
          mt={1}
          text={warning.text}
          fullWidth
        />
      ))}
    </Box>
  );
}

ProductSearch.propTypes = {
  onSelect: PropTypes.func,
  error: PropTypes.string,
  product: PropTypes.shape({
    materialId: PropTypes.string,
  }),
  id: PropTypes.string,
  caseType: PropTypes.string,
  productReturn: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

ProductSearch.defaultProps = {
  onSelect: null,
  error: "",
  product: {},
  id: "",
  caseType: "",
  productReturn: {},
  disabled: false,
};

export default ProductSearch;
