import {
  CONCLUSION_MAX_LENGTH,
  FieldErrors,
  validatePermittedCompensation,
  validateAttachments,
  isInvalidFileNumbers,
  isInvalidFileLimitPerUserType,
  getAttachmentListNotYetUploaded,
} from "../../../../utils/validations/validations";
import { ProductDisposers } from "../../productHandlingInstructions/productHandlingInstructions";
import { checkIfNewReturnAddressFormat } from "../../../../utils/case/case";

export const getProductHandlingErrors = localHandlingData => {
  const errorArray = [];

  const { isOriolaWaitingForInstructions } = localHandlingData;
  if (isOriolaWaitingForInstructions == null) {
    errorArray.push({
      spdOriolaIsWaitingForInstructions:
        FieldErrors.ORIOLA_IS_WAITING_FURTHER_INSTRUCTIONS_ERROR,
    });
  }

  return errorArray;
};

export const getProductHandlingInstructionFilledErrors = (
  localHandlingData,
  reclamationCase,
  messages,
  userData
) => {
  const errorArray = [];

  const { productHandlingInstructions = {} } = localHandlingData;
  const {
    isReturnToPrincipalRequired,
    returnAddress,
    isAuthorizedRecipientConfirmed,
    attachments = [],
  } = productHandlingInstructions;

  // check address only if the checkbox is selected (this is not mandatory)
  if (isReturnToPrincipalRequired != null) {
    const addressMissing =
      isReturnToPrincipalRequired === true &&
      (returnAddress == null || returnAddress === "");
    if (addressMissing) {
      errorArray.push({
        spdReturnToPrincipalAddressRequired:
          FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
      });
    }

    const isNewReturnAddressFormat =
      checkIfNewReturnAddressFormat(returnAddress);
    if (isReturnToPrincipalRequired && isNewReturnAddressFormat) {
      if (!returnAddress.company) {
        errorArray.push({
          spdReturnToPrincipalAddressCompanyFieldRequired:
            FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
        });
      }
      if (!returnAddress.contactName) {
        errorArray.push({
          spdReturnToPrincipalAddressContactNameFieldRequired:
            FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
        });
      }
      if (!returnAddress.phoneNumber) {
        errorArray.push({
          spdReturnToPrincipalAddressPhoneNumberFieldRequired:
            FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
        });
      }
      if (!returnAddress.address) {
        errorArray.push({
          spdReturnToPrincipalAddressAddressFieldRequired:
            FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
        });
      }
      if (!returnAddress.country) {
        errorArray.push({
          spdReturnToPrincipalAddressCountryFieldRequired:
            FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
        });
      }
    }

    const recipientConfirmationMissing =
      isReturnToPrincipalRequired === true &&
      isAuthorizedRecipientConfirmed !== true;
    if (recipientConfirmationMissing) {
      errorArray.push({
        spdReturnToPrincipalRecipientConfirmationRequired:
          FieldErrors.PRODUCT_RETURN_REQUIRED_TO_PRINCIPAL_RETURN_ADDRESS_MANDATORY,
      });
    }
    if (attachments.length) {
      const singleFileValidationError = validateAttachments(attachments);
      if (singleFileValidationError.length) {
        errorArray.push({
          spdReturnToPrincipalInvalidAttachment:
            FieldErrors[singleFileValidationError.type],
        });
      }
      const fileNumberError = isInvalidFileNumbers(
        getAttachmentListNotYetUploaded(attachments)
      );
      if (fileNumberError) {
        errorArray.push({
          spdReturnToPrincipalInvalidAttachment:
            FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE,
        });
      }
      const fileLimitError = isInvalidFileLimitPerUserType(
        userData,
        reclamationCase,
        messages,
        getAttachmentListNotYetUploaded(attachments)
      );
      if (fileLimitError) {
        errorArray.push({
          spdReturnToPrincipalInvalidAttachment:
            FieldErrors.ATTACHMENT_OVER_LIMIT,
        });
      }
    }
  }
  return errorArray;
};

export const getProductHandlingInstructionAllErrors = localHandlingData => {
  const errorArray = [];

  const { productHandlingInstructions = {} } = localHandlingData;
  const {
    methodOfDisposal,
    isCertificateOfDestructionRequired,
    isPhotographRequested,
    photographInstructions,
  } = productHandlingInstructions;

  errorArray.push(
    ...getProductHandlingInstructionFilledErrors(localHandlingData)
  );

  // check certification only if methodOfDisposal is selected (not mandatory)
  if (methodOfDisposal != null) {
    const certificationOfDisposalSelectionMissing =
      methodOfDisposal === ProductDisposers.ORIOLA &&
      isCertificateOfDestructionRequired == null;
    if (certificationOfDisposalSelectionMissing) {
      errorArray.push({
        spdCertificationOfDisposalValueRequired:
          FieldErrors.CERTIFICATION_OF_DISPOSAL_VALUE_MANDATORY,
      });
    }
  }

  if (isPhotographRequested && !photographInstructions) {
    errorArray.push({
      spdPhotographInstructionsRequired:
        FieldErrors.PHOTOGRAPH_INSTRUCTIONS_MANDATORY,
    });
  }

  return errorArray;
};

export const getCompensationInstructionsErrors = () => [];

export const getCompensationInformationFilledFieldErrors = handlingData => {
  const errorArray = [];

  const { compensationInformation = {} } = handlingData;
  const { isCustomerCompensated, compensations = [] } = compensationInformation;

  if (isCustomerCompensated === true) {
    const compensationsValidationResult =
      validatePermittedCompensation(compensations);

    if (
      compensationsValidationResult &&
      !compensationsValidationResult.result
    ) {
      errorArray.push({
        compensationsError: compensationsValidationResult.errors,
      });
    }
  }
  return errorArray;
};

export const getCompensationInformationAllErrors = handlingData => {
  const errorArray = [];

  const { compensationInformation = {} } = handlingData;
  const { isCustomerCompensated } = compensationInformation;

  if (isCustomerCompensated == null) {
    errorArray.push({
      spdCustomerCompensationIsCompensatedError:
        FieldErrors.CUSTOMER_COMPENSATION_IS_COMPENSATED_MANDATORY,
    });
  }

  errorArray.push(...getCompensationInformationFilledFieldErrors(handlingData));
  return errorArray;
};

export const getPermissionRequiredFromPharmaCompanyErrors =
  localHandlingData => {
    const errorArray = [];

    const { isPermissionFromPharmaCompanyRequiredForCompensation } =
      localHandlingData;
    if (isPermissionFromPharmaCompanyRequiredForCompensation == null) {
      errorArray.push({
        spdPermissionRequiredFromPharmaCompany:
          FieldErrors.PERMISSION_REQUIRED_FROM_PHARMA_COMPANY_MANDATORY,
      });
    }
    return errorArray;
  };

export const getProductReceivedReturnedAmountError = localHandlingData => {
  const errorArray = [];
  const { productHandlingInformation } = localHandlingData;
  if (
    productHandlingInformation &&
    productHandlingInformation.productReceived === "PRODUCT_RECEIVED"
  ) {
    const { amountReturned } = productHandlingInformation;
    if (!amountReturned) {
      errorArray.push({ amountReturnedError: FieldErrors.MANDATORY });
    } else if (
      parseInt(amountReturned, 10) > 99999 ||
      parseInt(amountReturned, 10) < 1
    ) {
      errorArray.push({ amountReturnedError: FieldErrors.INVALID_NUMBER });
    }
  }
  return errorArray;
};

export const getProductHandlingInformationError = localHandlingData => {
  const errorArray = [];

  const { productHandlingInformation } = localHandlingData;

  if (
    !productHandlingInformation ||
    !productHandlingInformation.productReceived
  ) {
    errorArray.push({ productHandlingInfoError: FieldErrors.MANDATORY });
  }
  if (
    productHandlingInformation &&
    productHandlingInformation.productReceived === "PRODUCT_RECEIVED"
  ) {
    const { isDisposedAtOriola, isPhotographsTaken, isSentToPharmaCompany } =
      localHandlingData.productHandlingInformation;

    if (!isDisposedAtOriola && !isPhotographsTaken && !isSentToPharmaCompany) {
      errorArray.push({ productReceivedError: FieldErrors.MANDATORY });
    }

    errorArray.push(
      ...getProductReceivedReturnedAmountError(localHandlingData)
    );
  }
  return errorArray;
};

export const getConclusionErrors = (
  reclamationCase,
  localHandlingData,
  isConclusionRequired,
  messages,
  userData
) => {
  const errorArray = [];

  if (reclamationCase.isStatementRequired === true) {
    const { conclusion } = localHandlingData;
    if (conclusion) {
      if (conclusion.conclusion?.length > CONCLUSION_MAX_LENGTH) {
        errorArray.push({
          conclusionError: FieldErrors.CONCLUSION_OVER_LENGTH,
        });
      }
      // Validate attachment formats
      else if (conclusion.attachments?.length) {
        const singleFileValidationError = validateAttachments(
          conclusion.attachments
        );
        if (singleFileValidationError.length) {
          errorArray.push({
            conclusionError: FieldErrors[singleFileValidationError.type],
          });
        }
        const fileNumberError = isInvalidFileNumbers(
          getAttachmentListNotYetUploaded(conclusion.attachments)
        );
        if (fileNumberError) {
          errorArray.push({
            conclusionError: FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE,
          });
        }
        const fileLimitError = isInvalidFileLimitPerUserType(
          userData,
          reclamationCase,
          messages,
          getAttachmentListNotYetUploaded(conclusion.attachments)
        );
        if (fileLimitError) {
          errorArray.push({
            conclusionError: FieldErrors.ATTACHMENT_OVER_LIMIT,
          });
        }
      }
    } else if (isConclusionRequired) {
      errorArray.push({ conclusionError: FieldErrors.MANDATORY });
    }
  }

  return errorArray;
};
