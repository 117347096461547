import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import CaseStatusButtonGroup from "./caseStatusButtonGroup";
import CaseTypeSelect from "./caseTypeSelect";
import HandlerSelect from "./handlerSelect";
import ProductDetailsSelect from "./productDetailsSelect";
import DaterangeSelect from "./daterangeSelect";
import SortSelect from "./sortSelect";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";
import PrincipalAndSupplierFilter from "./principalAndSupplierFilter/principalAndSupplierFilter";

function CaseFilters({ updateFilters, fetchCases }) {
  const { userData } = useSelector(state => state.user);
  const filters = useSelector(state => state.casesFilters);
  const userIsOriolaUser = isOriolaUser(userData);
  const userIsPrincipalUser = isPharmaceuticalCompany(userData);
  const updateFiltersAndFetchCases = newFilters => {
    updateFilters(newFilters);
    fetchCases(newFilters);
  };

  return (
    <Box px="3rem" py="2rem" display="flex" gridGap="2em" flexWrap="wrap">
      <CaseStatusButtonGroup
        id="case-filter-status"
        caseStatus={filters.caseStatus}
        caseSubStatus={filters.caseSubStatus}
        onCaseStatusSelected={(caseStatus, caseSubStatus) => {
          updateFiltersAndFetchCases({ ...filters, caseStatus, caseSubStatus });
        }}
        userIsOriolaUser={userIsOriolaUser}
      />
      <CaseTypeSelect
        id="case-filter-type"
        flexBasis="300px"
        flexGrow="1"
        flexShrink="1"
        maxWidth="500px"
        caseType={filters.caseType}
        onCaseTypeSelected={value =>
          updateFiltersAndFetchCases({ ...filters, caseType: value })
        }
      />
      <DaterangeSelect
        id="case-filter-daterange"
        flexBasis="360px"
        flexGrow="1"
        flexShrink="0"
        maxWidth="500px"
        startDate={filters.startDate}
        endDate={filters.endDate}
        onDatesSelected={(startDate, endDate) =>
          updateFiltersAndFetchCases({ ...filters, startDate, endDate })
        }
      />
      {userIsOriolaUser && (
        <HandlerSelect
          id="case-filter-handler"
          flexBasis="400px"
          flexGrow="1"
          flexShrink="3"
          maxWidth="300px"
          handler={filters.subQueue ? filters.subQueue : filters.handler}
          onHandlerSelected={(handler, subQueue) =>
            updateFiltersAndFetchCases({ ...filters, handler, subQueue })
          }
        />
      )}
      {(userIsOriolaUser || userIsPrincipalUser) && (
        <ProductDetailsSelect
          id="case-filter-product-details"
          flexBasis="150px"
          flexGrow="1"
          flexShrink="3"
          maxWidth="300px"
          productDetail={filters.productDetail}
          onProductDetailsSelect={value =>
            updateFiltersAndFetchCases({ ...filters, productDetail: value })
          }
          isPrincipalUser={userIsPrincipalUser}
        />
      )}
      {userIsOriolaUser && (
        <PrincipalAndSupplierFilter
          onFiltersSelected={(profitCenters, supplierNumbers) => {
            updateFiltersAndFetchCases({
              ...filters,
              profitCenters,
              supplierNumbers,
            });
          }}
          currentProfitCenterFilters={filters.profitCenters}
          currentSupplierFilters={filters.supplierNumbers}
        />
      )}
      <SortSelect
        id="case-filter-sort"
        flexBasis="300px"
        flexGrow="1"
        flexShrink="3"
        maxWidth="300px"
        sortType={filters.sortType}
        onSortTypeSelected={value => {
          updateFiltersAndFetchCases({ ...filters, sortType: value });
        }}
      />
    </Box>
  );
}

CaseFilters.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  fetchCases: PropTypes.func.isRequired,
};

CaseFilters.defaultProps = {};

export default CaseFilters;
