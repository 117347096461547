/**
 * Map product data from format returned by search api to client format
 */

const naToUndefined = x => (x === "n/a" ? undefined : x);

const mapProductsFromApiSearchData = products =>
  (products || []).map(p => ({
    materialId: p.materialId,
    vnr: naToUndefined(p.vnr),
    msiCode: naToUndefined(p.msiCode),
    localizedName: p.localizedName,
    localizedSupplier: p.languageSuppliers,
    contentAmount: p.contentAmount,
    contentAmountFreeText: p.contentAmountFreeText,
    narcoticsClassFI: p.narcoticsClassFI,
    isFMDApplicable: p.isFMDApplicable,
    isCytostatic: p.isCytostatic,
    pharmaceuticalForm: p.pharmaceuticalForm,
    marketingAuthorizationHolder: p.marketingAuthorizationHolder,
    gtinCodes: p.gtinCodes,
    profitCenter: p.profitCenter,
    safeTubeNeededInReturning: p.safeTubeNeededInReturning,
    supplierNumber: p.supplierNumber,
    supplierName: p.supplierName,
    isDamageProductReturnRequired: p.isDamageProductReturnRequired,
    isReturnable: p.isReturnable,
    isAnesthetics: p.isAnesthetics,
  }));

export default mapProductsFromApiSearchData;
