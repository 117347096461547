import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-cycle
import { FieldTitle } from "..";
import { StatefullTextField } from "../../../common";
import { getValidationErrorMessage } from "../../../../utils/validations/validations";

const useStyles = makeStyles(theme => ({
  productField: {
    width: "100%",
    maxWidth: "266px",
    "& input": {
      backgroundColor: "white",
    },
  },
  field: {
    width: "266px",
    marginRight: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "calc(100%/3 - 22px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(4),
    },
    "& > :nth-child(1)": {
      flexGrow: 1,
      bottom: "0",
      [theme.breakpoints.down("sm")]: {
        position: "inherit",
        bottom: "none",
      },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -theme.spacing(3.2),
    },
  },
}));

function ProductInput({ product, disabled, onChange, id, error }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box display="flex" sx={{ flexWrap: "wrap" }} zIndex="1">
      <div className={classes.field} style={{ position: "relative" }}>
        <FieldTitle title={t("caseItemFields.productName")} />
        <StatefullTextField
          initialValue={product.productName}
          error={!!error.productName}
          className={classes.productField}
          helperText={getValidationErrorMessage(error.productName, t)}
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={newValue => {
            onChange({
              ...product,
              productName: newValue,
            });
          }}
          id={`${id}-product-name`}
        />
      </div>
      <div className={classes.field}>
        <FieldTitle title={t("caseItemFields.productNumber")} optional />
        <StatefullTextField
          initialValue={product.materialId}
          error={!!error.materialId}
          className={classes.productField}
          helperText={getValidationErrorMessage(error.materialId, t)}
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={newValue => {
            onChange({
              ...product,
              materialId: newValue,
            });
          }}
          id={`${id}-material-id`}
        />
      </div>
      <div className={classes.field}>
        <FieldTitle title={t("caseItemFields.vnr")} optional />
        <StatefullTextField
          initialValue={product.vnr}
          error={!!error.vnr}
          className={classes.productField}
          helperText={getValidationErrorMessage(error.vnr, t)}
          variant="outlined"
          margin="dense"
          disabled={disabled}
          onChange={newValue => {
            onChange({
              ...product,
              vnr: newValue,
            });
          }}
          id={`${id}-vnr`}
        />
      </div>
    </Box>
  );
}

ProductInput.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string,
    materialId: PropTypes.string,
    vnr: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.shape({
    productName: PropTypes.string,
    materialId: PropTypes.string,
    vnr: PropTypes.string,
  }),
};

ProductInput.defaultProps = {
  error: {},
  product: {},
  disabled: false,
  onChange: null,
  id: "",
};

export default ProductInput;
