import React, { useState } from "react";
import PropTypes from "prop-types";
import { AlertDialog, OriolaColors } from "@oriola-origo/origo-ui-core";
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { UserOueueIcon } from "../../../images";
import CaseSubStatus from "../../../constants/caseSubStatus";

const useStyles = makeStyles(theme => ({
  currentSubStatusText: {
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(6),
  },
  radioButton: {
    color: OriolaColors.secondaryNavyBlue,
  },
  label: {
    ...theme.typography.subtitle2,
    fontWeight: "400",
    color: OriolaColors.secondaryNavyBlue,
  },
}));

const OPEN = "open;";

function SetSubStatusDialog({
  open,
  reclamationCase,
  handleCloseDialog,
  handleChangeSubStatus,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [subStatusValue, setSubStatusValue] = useState(
    reclamationCase.caseSubStatus || OPEN
  );

  const subStatusToText = targetSubStatus => {
    if (targetSubStatus === OPEN) {
      return t("changeSubStatus.open");
    }
    return t(`changeSubStatus.${targetSubStatus}`);
  };

  const doHandleChangeSubStatus = () => {
    handleChangeSubStatus(subStatusValue === OPEN ? null : subStatusValue);
  };

  return (
    <AlertDialog
      open={open}
      onCancel={handleCloseDialog}
      id="set-substatus-dialog"
      onOk={doHandleChangeSubStatus}
      title={t("changeSubStatus.changeStatus")}
      cancelButtonText={t("cancel")}
      okButtonText={t("changeSubStatus.changeStatus")}
      disableBackdropClick
      width="400px"
      maxWidth="xs"
    >
      <Typography className={classes.currentSubStatusText}>
        {`${t("changeSubStatus.currentStatus")}: ${subStatusToText(
          reclamationCase.caseSubStatus || OPEN
        )}`}
      </Typography>
      <Box mb={4}>
        <Box my={4} textAlign="center">
          <UserOueueIcon />
        </Box>
        <RadioGroup
          className={classes.radioGroup}
          value={subStatusValue}
          onChange={e => setSubStatusValue(e.target.value)}
        >
          {[OPEN].concat(Object.values(CaseSubStatus)).map(caseSubStatus => (
            <FormControlLabel
              key={caseSubStatus}
              value={caseSubStatus}
              control={<Radio className={classes.radioButton} />}
              label={
                <Typography className={classes.label}>
                  {subStatusToText(caseSubStatus)}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </Box>
    </AlertDialog>
  );
}

SetSubStatusDialog.propTypes = {
  open: PropTypes.bool,
  reclamationCase: PropTypes.shape({
    caseSubStatus: PropTypes.string,
  }),
  handleCloseDialog: PropTypes.func,
  handleChangeSubStatus: PropTypes.func,
};

SetSubStatusDialog.defaultProps = {
  open: false,
  reclamationCase: {},
  handleCloseDialog: null,
  handleChangeSubStatus: null,
};

export default SetSubStatusDialog;
