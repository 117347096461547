import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { FieldTitle, ProductSearch } from "..";
import ProductInput from "./productInput";
import { CaseType } from "../../../../constants/caseType";

function ProductField({
  itemData,
  classes,
  id,
  productFieldName,
  config,
  error,
  updateItem,
  updateItemValidationError,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const product = itemData[productFieldName] || {};
  const { caseType, productReturn } = useSelector(state => state.case.case);

  const switchProductComponent = customerAddedProduct => {
    const productData = customerAddedProduct
      ? { customerAddedProduct: true }
      : undefined;
    dispatch(
      updateItem({
        ...itemData,
        [productFieldName]: productData,
      })
    );
  };
  const updateProductData = value => {
    dispatch(
      updateItemValidationError(itemData.uuid, {
        [productFieldName]: undefined,
      })
    );
    let updateEntry = {
      ...product,
      ...value,
    };
    if (!value) {
      updateEntry = undefined;
    }

    if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
      dispatch(
        updateItem({
          ...itemData,
          [productFieldName]: updateEntry,
          productCode:
            updateEntry &&
            updateEntry.gtinCodes &&
            updateEntry.gtinCodes[0] &&
            updateEntry.gtinCodes[0].gtin,
        })
      );
    } else {
      dispatch(
        updateItem({
          ...itemData,
          [productFieldName]: updateEntry,
        })
      );
    }
  };
  const renderProductInput = () => (
    <Box display="flex" sx={{ flexWrap: "wrap" }}>
      <Box
        display="flex"
        sx={{ flexWrap: "wrap" }}
        className={classes.greyBackground}
        flexDirection="column"
        alignItems="flex-end"
      >
        <ProductInput
          className={classes.productInput}
          product={product}
          onChange={updateProductData}
          error={error}
          id={id}
          disabled={disabled}
        />
        <Typography
          className={`${classes.findProductButton} ${classes.instruction}`}
          onClick={() => {
            switchProductComponent(false);
          }}
          marginRight="20px"
        >
          {t("searchFromProducts")}
        </Typography>
      </Box>
    </Box>
  );

  const renderProductSearch = () => (
    <Box display="flex" sx={{ flexWrap: "wrap" }}>
      <Box position="relative" style={{ width: "700px", marginRight: "20px" }}>
        <Box display="flex" flexWrap="wrap">
          <FieldTitle
            title={t(`caseItemFields.${productFieldName}`)}
            type="selection"
            optional={config.optional}
          />
          <Box
            display="flex"
            marginLeft="auto"
            alignItems="end"
            marginBottom="5px"
          >
            <Typography
              className={`${classes.findProduct} ${classes.instruction}`}
            >
              {t("addMissingProduct.before")}
            </Typography>
            <Typography
              className={`${classes.findProductButton} ${classes.instruction}`}
              onClick={
                !disabled
                  ? () => {
                      switchProductComponent(true);
                    }
                  : null
              }
              id={`${id}-add-customer-product`}
            >
              {t("addMissingProduct.after")}
            </Typography>
          </Box>
        </Box>
        <ProductSearch
          product={product || {}}
          error={error}
          onSelect={updateProductData}
          id={id}
          caseType={caseType}
          productReturn={productReturn}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
  return (
    <div className={classes.rowContainer}>
      {product.customerAddedProduct
        ? renderProductInput()
        : renderProductSearch()}
    </div>
  );
}

ProductField.propTypes = {
  itemData: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  classes: PropTypes.shape({
    rowContainer: PropTypes.string,
    findProduct: PropTypes.string,
    findProductButton: PropTypes.string,
    instruction: PropTypes.string,
    productInput: PropTypes.string,
    greyBackground: PropTypes.string,
  }),
  id: PropTypes.string,
  productFieldName: PropTypes.string.isRequired,
  config: PropTypes.shape({
    optional: PropTypes.bool,
  }),
  error: PropTypes.string,
  updateItem: PropTypes.func,
  updateItemValidationError: PropTypes.func,
  disabled: PropTypes.bool,
};

ProductField.defaultProps = {
  itemData: {},
  classes: {},
  id: "",
  config: {},
  error: "",
  updateItem: null,
  updateItemValidationError: null,
  disabled: false,
};

export default ProductField;
