import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { AlertDialog, Progress } from "@oriola-origo/origo-ui-core";
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { UserOueueIcon } from "../../../images";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3.5),
    marginLeft: theme.spacing(1),
  },
  currentQueue: {
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(6),
  },
  description: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  internalMessage: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginBottom: theme.spacing(1),
    display: "inline",
  },
  optional: {
    fontWeight: "100",
  },
  errorText: {
    fontSize: "1rem",
    color: theme.palette.error.main,
    marginBottom: theme.spacing(3),
  },
  subQueueSelection: {
    marginLeft: "35px",
  },
}));

function ChangeQueueDialog({
  department,
  setDepartment,
  internalMessage,
  setInternalMessage,
  open,
  reclamationCase,
  handleCloseDialog,
  handleContinue,
  sourceQueue,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { changingQueue } = useSelector(state => state.case);
  const dispatch = useDispatch();
  const { messageError } = useSelector(state => state.validationErrors);
  const { subQueue } = reclamationCase;
  const { queue } = department;

  const returnDepartmentSubQueues = Object.values(
    EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
  ).filter(
    returnsSubQueue =>
      returnsSubQueue !==
      EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
        .RETURN_DEPARTMENT_NO_SUBQUEUE
  );

  const onValueChanged = e => {
    setDepartment({
      queue: e.target.value,
      subQueue: null,
    });
    dispatch(updateValidationError({ messageError: "" }));
  };

  const setSubDepartmentQueueValues = e => {
    if (queue !== EventQueueTarget.RETURN_DEPARTMENT) {
      setDepartment({
        queue: EventQueueTarget.RETURN_DEPARTMENT,
        subQueue: e.target.value,
      });
    } else {
      setDepartment({
        queue,
        subQueue: e.target.value,
      });
    }
    dispatch(updateValidationError({ messageError: "" }));
  };

  const handleInternalMessageChanged = e => {
    setInternalMessage(e.target.value);
  };

  const renderPrecheckedQueue = () =>
    (!queue && sourceQueue === EventQueueTarget.RETURN_DEPARTMENT) ||
    queue === EventQueueTarget.RETURN_DEPARTMENT;

  const renderPrecheckedSubQueue = (departmnt, value) =>
    (!queue && subQueue === value) || value === departmnt.subQueue;

  return (
    <AlertDialog
      open={open}
      onCancel={handleCloseDialog}
      id="change-queue-dialog"
      onOk={() => {
        if (!queue && !department.subQueue) {
          dispatch(
            updateValidationError({ messageError: FieldErrors.QUEUE_MANDATORY })
          );
          return;
        }
        if (changingQueue !== true) {
          department.subQueue
            ? handleContinue(queue, internalMessage, department.subQueue)
            : handleContinue(queue, internalMessage);
        }
      }}
      title={t("changeQueue.title")}
      cancelButtonText={t("cancel")}
      okButtonText={t("transfer")}
      disableBackdropClick
      width="400px"
      maxWidth="xs"
      hideCancelButton={changingQueue}
      hideOkButton={changingQueue}
    >
      <Typography className={classes.currentQueue}>
        {t("changeQueue.currentQueue")}
        :&nbsp;
        {t(`queues.${sourceQueue}`)}
      </Typography>
      <Box className={classes.container}>
        <Box textAlign="center">
          <UserOueueIcon />
        </Box>
        <Typography
          className={`${classes.currentQueue} ${classes.description}`}
        >
          {t("changeQueue.description")}
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          value={queue}
          onClick={onValueChanged}
        >
          <Box>
            <FormControlLabel
              value={EventQueueTarget.RETURN_DEPARTMENT}
              control={<Radio classes={{ root: classes.radioIcon }} />}
              label={
                <Typography className={classes.label}>
                  {t("queues.RETURN_DEPARTMENT")}
                </Typography>
              }
              className={classes.formControlLabel}
              disabled={
                !subQueue && sourceQueue === EventQueueTarget.RETURN_DEPARTMENT
              }
              checked={renderPrecheckedQueue()}
            />
            <RadioGroup
              className={classes.subQueueSelection}
              value={department.subQueue}
              onChange={setSubDepartmentQueueValues}
            >
              {returnDepartmentSubQueues.map(target => (
                <FormControlLabel
                  key={target}
                  value={target}
                  control={<Radio classes={{ root: classes.radioIcon }} />}
                  label={
                    <Typography className={classes.label}>
                      {t(`subQueues.returnDepartment.${target}`)}
                    </Typography>
                  }
                  className={classes.formControlLabel}
                  checked={renderPrecheckedSubQueue(department, target)}
                />
              ))}
            </RadioGroup>
          </Box>

          {sourceQueue !== EventQueueTarget.INVOICING_DEPARTMENT && (
            <FormControlLabel
              value={EventQueueTarget.INVOICING_DEPARTMENT}
              control={<Radio classes={{ root: classes.root }} />}
              label={
                <Typography className={classes.label}>
                  {t("queues.INVOICING_DEPARTMENT")}
                </Typography>
              }
            />
          )}
          {sourceQueue !== EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT && (
            <FormControlLabel
              value={EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT}
              control={<Radio classes={{ root: classes.root }} />}
              label={
                <Typography className={classes.label}>
                  {t("queues.CUSTOMER_SERVICE_DEPARTMENT")}
                </Typography>
              }
            />
          )}
          {sourceQueue !== EventQueueTarget.SCC && (
            <FormControlLabel
              value={EventQueueTarget.SCC}
              control={<Radio classes={{ root: classes.root }} />}
              label={
                <Typography className={classes.label}>
                  {t("queues.SCC")}
                </Typography>
              }
            />
          )}
          {sourceQueue !== EventQueueTarget.TRANSLATIONS &&
            reclamationCase.translationsRequired && (
              <FormControlLabel
                value={EventQueueTarget.TRANSLATIONS}
                control={<Radio classes={{ root: classes.root }} />}
                label={
                  <Typography className={classes.label}>
                    {t("queues.TRANSLATIONS")}
                  </Typography>
                }
              />
            )}
          {sourceQueue !== EventQueueTarget.TRANSPORTATION && (
            <FormControlLabel
              value={EventQueueTarget.TRANSPORTATION}
              control={<Radio classes={{ root: classes.root }} />}
              label={
                <Typography className={classes.label}>
                  {t("queues.TRANSPORTATION")}
                </Typography>
              }
            />
          )}
        </RadioGroup>
        <FormHelperText className={classes.errorText}>
          {getValidationErrorMessage(messageError, t)}
        </FormHelperText>

        <Typography className={`${classes.internalMessage}`} noWrap>
          {t("changeQueue.internalMessage")}
        </Typography>
        <Typography
          className={`${classes.internalMessage} ${classes.optional}`}
          noWrap
        >
          &nbsp;
          {t("changeQueue.optional")}
        </Typography>
        <Box mb={4}>
          <TextField
            value={internalMessage}
            onChange={event => handleInternalMessageChanged(event)}
            id="internal-message-input"
            fullWidth
            multiline
            rows="3"
            variant="outlined"
            placeholder={t("message.placeholder")}
            margin="dense"
          />
        </Box>
        {changingQueue && <Progress size={25} show />}
      </Box>
    </AlertDialog>
  );
}

ChangeQueueDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleContinue: PropTypes.func,
  sourceQueue: PropTypes.string,
  department: PropTypes.shape({
    queue: PropTypes.string,
    subQueue: PropTypes.string,
  }),
  setDepartment: PropTypes.func,
  internalMessage: PropTypes.string,
  setInternalMessage: PropTypes.func,
  reclamationCase: PropTypes.shape({
    subQueue: PropTypes.string,
    translationsRequired: PropTypes.bool,
  }),
};

ChangeQueueDialog.defaultProps = {
  open: false,
  handleCloseDialog: null,
  handleContinue: null,
  sourceQueue: "",
  department: {
    queue: "",
    subQueue: "",
  },
  setDepartment: null,
  internalMessage: "",
  setInternalMessage: null,
  reclamationCase: {
    subQueue: "",
    translationsRequired: false,
  },
};

export default ChangeQueueDialog;
