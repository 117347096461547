import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, Box } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SearchField from "./caseFilters/searchField";
import {
  getOrganizationIdsByRoles,
  AllRolesArray,
  isOriolaUser,
  isPharmaceuticalCompany,
} from "../auth/permission";
import Cases from "./cases";
import { fetchCases, clearCasesList, setFetchFilters } from "../../redux";
import CaseFilters from "./caseFilters/caseFilters";
import {
  getUrlParamsFromObject,
  getObjectFromUrlParams,
} from "../../utils/url/url";
import Delay from "../../utils/delay/delay";
import { CaseStatus } from "../../constants/caseStatus";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "none",
    marginTop: 0,
    width: "100%",
  },
  clearButton: {
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "50px",
    "& p": {
      fontWeight: "bold",
    },
  },
  activeButton: {
    backgroundColor: OriolaColors.primaryOrange,
    color: OriolaColors.White,
  },
  exportButtonWrapper: {
    marginLeft: "auto",
  },
  exportButton: {
    backgroundColor: "transparent",
    paddingRight: 0,
  },
  exportLoader: {
    marginRight: theme.spacing(3),
  },
  title: {
    paddingRight: "1rem",
    minWidth: "110px",
  },
  caseTable: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  date: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  titleText: {
    ...theme.typography.h4,
    color: "#2b3e5b",
  },
  importButtonText: {
    ...theme.typography.subtitle3,
    display: "flex",
    alignItems: "baseline",
    marginTop: theme.spacing(2),
  },
}));

const PAGE_SIZE = 50;
const debouncer = new Delay();

function CasesView() {
  // !!!!!!!! Do not read any state from state.casesList in this file, otherwise caseFilter component will
  // be rerendered after each update to caseList.
  const classes = useStyles();
  const { t } = useTranslation();
  const filters = useSelector(state => state.casesFilters);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.user);
  const organizationIdsByRoles = getOrganizationIdsByRoles(
    userData,
    AllRolesArray
  );
  const organizationIdsByUser =
    isOriolaUser(userData) || isPharmaceuticalCompany(userData)
      ? null
      : organizationIdsByRoles;
  const [organizationIds] = useState(organizationIdsByUser);

  const updateFilters = updatedFilters => {
    dispatch(setFetchFilters(updatedFilters));
    const path = window.location.origin + window.location.pathname;
    const urlParams = getUrlParamsFromObject(updatedFilters);
    window.history.replaceState(null, null, `${path}?${urlParams}`);
  };

  const fetchCasesWithFilters = (newFilters, doAbortPreviousCall) => {
    dispatch(
      fetchCases(
        0,
        PAGE_SIZE,
        { ...newFilters, organizationIds },
        doAbortPreviousCall
      )
    );
  };

  useEffect(() => {
    const filtersFromUrlParams = getObjectFromUrlParams(window.location.search);
    const organizationId =
      filtersFromUrlParams.organizationId || userData?.defaultOrganizationId;
    const filtersWithUrlFilters = {
      ...{ ...filters, caseStatus: CaseStatus.OPEN_SUBMITTED },
      ...filtersFromUrlParams,
      organizationId,
    };

    updateFilters(filtersWithUrlFilters);
    fetchCasesWithFilters(filtersWithUrlFilters);
    dispatch(clearCasesList());
  }, [organizationIds]); // eslint-disable-line

  const updateSearchTextAndFetchCasesDelayed = searchText => {
    if (searchText.length > 100) {
      searchText = searchText.substring(0, 100);
    }
    const filtersWithText = { ...filters, text: searchText };
    updateFilters(filtersWithText);
    debouncer.run(() => {
      dispatch(clearCasesList());
      fetchCasesWithFilters(filtersWithText, true);
    }, 600);
  };

  return (
    <Box width="95%" margin="auto">
      <Paper className={classes.root} id="cases-root">
        <Box px="3rem" pt="2rem" display="flex" justifyContent="space-between">
          <Typography id="cases-header" className={classes.titleText}>
            {t("cases")}
          </Typography>
          <SearchField
            label={t("searchCaseLabel")}
            searchText={filters.text}
            onSearchTextChanged={updateSearchTextAndFetchCasesDelayed}
          />
        </Box>
        <CaseFilters
          updateFilters={updateFilters}
          fetchCases={newFilters => {
            dispatch(clearCasesList());
            fetchCasesWithFilters(newFilters, true);
          }}
        />
        <Cases
          organizationIds={organizationIds}
          classes={classes}
          pageSize={PAGE_SIZE}
        />
      </Paper>
    </Box>
  );
}

CasesView.propTypes = {};

CasesView.defaultProps = {};

export default CasesView;
