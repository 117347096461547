import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import DetailRow from "../detailRow";
import { Can, Permission, ANY_CUSTOMER } from "../../../auth";
import getModifierStrOrNull from "./getModifierStrOrNull";
import getValueIfNotNull from "./getValueIfNotNull";
import { ProductReceivedOrNot } from "../../productHandlingInformation/productHandlingInformation";

function ProductHandlingInformationView({
  productHandlingInformation,
  userData,
  modifications,
  ...containerProps
}) {
  const { t } = useTranslation();

  const {
    productReceived,
    isDisposedAtOriola,
    isPhotographsTaken,
    amountReturned,
    isSentToPharmaCompany,
    handlingInformation,
  } = productHandlingInformation;

  return (
    <Box {...containerProps}>
      <Typography variant="subtitle2">
        {t("productHandlingInformation.title").toUpperCase()}
      </Typography>
      <Box mt={3}>
        <DetailRow
          keyName={t("productHandlingInformation.productReturns")}
          value={getValueIfNotNull(
            productReceived,
            productReceived === ProductReceivedOrNot.PRODUCT_RECEIVED
              ? t("productHandlingInformation.productAlreadyReturned")
              : t("productHandlingInformation.productNotYetReturned")
          )}
          secondaryValue={getModifierStrOrNull(
            productReceived != null,
            modifications,
            "productHandlingInformation.productReceived"
          )}
        />
        {productReceived === ProductReceivedOrNot.PRODUCT_RECEIVED && (
          <DetailRow
            keyName={t("productHandlingInformation.amountOfProductsReturned")}
            value={amountReturned}
            secondaryValue={getModifierStrOrNull(
              amountReturned != null,
              modifications,
              "productHandlingInformation.amountReturned"
            )}
          />
        )}
        {productReceived === ProductReceivedOrNot.PRODUCT_RECEIVED && (
          <Can
            user={userData}
            perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
            customerContext={ANY_CUSTOMER}
          >
            <DetailRow
              keyName={t("productHandlingInformation.isSentToPharmaCompany")}
              value={getValueIfNotNull(
                isSentToPharmaCompany,
                isSentToPharmaCompany ? t("yes") : t("no")
              )}
              secondaryValue={getModifierStrOrNull(
                isSentToPharmaCompany != null,
                modifications,
                "productHandlingInformation.isSentToPharmaCompany"
              )}
            />
            <DetailRow
              keyName={t("productHandlingInformation.isDisposedAtOriola")}
              value={getValueIfNotNull(
                isDisposedAtOriola,
                isDisposedAtOriola ? t("yes") : t("no")
              )}
              secondaryValue={getModifierStrOrNull(
                isDisposedAtOriola != null,
                modifications,
                "productHandlingInformation.isDisposedAtOriola"
              )}
            />
            <DetailRow
              keyName={t("productHandlingInformation.isPhotographsTaken")}
              value={getValueIfNotNull(
                isPhotographsTaken,
                isPhotographsTaken ? t("yes") : t("no")
              )}
              secondaryValue={getModifierStrOrNull(
                isPhotographsTaken != null,
                modifications,
                "productHandlingInformation.isPhotographsTaken"
              )}
            />
          </Can>
        )}
        <Can
          user={userData}
          perform={Permission.HANDLING_DATA_PRINCIPAL_VIEW}
          customerContext={ANY_CUSTOMER}
        >
          <DetailRow
            keyName={t("productHandlingInformation.handlingInfo")}
            value={handlingInformation}
            secondaryValue={getModifierStrOrNull(
              handlingInformation != null,
              modifications,
              "productHandlingInformation.handlingInformation"
            )}
          />
        </Can>
      </Box>
    </Box>
  );
}

ProductHandlingInformationView.propTypes = {
  productHandlingInformation: PropTypes.shape({
    productReceived: PropTypes.string,
    isDisposedAtOriola: PropTypes.bool,
    isPhotographsTaken: PropTypes.bool,
    amountReturned: PropTypes.string,
    isSentToPharmaCompany: PropTypes.bool,
    handlingInformation: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({}).isRequired,
  modifications: PropTypes.shape({}),
};

ProductHandlingInformationView.defaultProps = {
  modifications: {},
};

export default ProductHandlingInformationView;
