import React from "react";
import PropTypes from "prop-types";
import { Button } from "@oriola-origo/origo-ui-core";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CasesTable from "./casesTable";
import { fetchCases, clearCasesList, setFetchFilters } from "../../redux";
import ExportReport from "./exportReport";
import {
  getObjectFromUrlParams,
  getUrlParamsFromObject,
} from "../../utils/url/url";
import { CaseStatus } from "../../constants/caseStatus";

const PAGE_SIZE = 50;

function Cases({ organizationIds, classes, pageSize }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { cases, fetching, overallCount } = useSelector(
    state => state.listCases
  );
  const filters = useSelector(state => state.casesFilters);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.user);

  const fetchNextPageCases = (startIndex, stopIndex) => {
    const count = stopIndex - startIndex + 1;
    const filtersWithOrg = { ...filters, organizationIds };
    dispatch(fetchCases(startIndex, count, filtersWithOrg));
  };

  const fetchCasesWithFilters = (newFilters, doAbortPreviousCall) => {
    dispatch(
      fetchCases(
        0,
        PAGE_SIZE,
        { ...newFilters, organizationIds },
        doAbortPreviousCall
      )
    );
  };

  const updateFilters = updatedFilters => {
    dispatch(setFetchFilters(updatedFilters));
    const path = window.location.origin + window.location.pathname;
    const urlParams = getUrlParamsFromObject(updatedFilters);
    window.history.replaceState(null, null, `${path}?${urlParams}`);
  };

  const clearFilters = () => {
    const { organizationId } = getObjectFromUrlParams(location.search);
    dispatch(clearCasesList());
    updateFilters({
      startDate: "YYYY-MM-DD",
      endDate: "YYYY-MM-DD",
      caseStatus: CaseStatus.OPEN_SUBMITTED,
      organizationId,
    });
    fetchCasesWithFilters(
      { caseStatus: CaseStatus.OPEN_SUBMITTED, organizationId },
      true
    );
  };

  const allNull = obj => Object.values(obj).every(value => value === null);

  const showActiveClearFilterButton = () =>
    Object.keys(filters).some(
      key =>
        filters[key] != null &&
        (key !== "caseStatus" || filters[key] !== CaseStatus.OPEN_SUBMITTED)
    );

  return (
    <Box>
      <Box display="flex" padding="0 3rem">
        <Box display="flex" alignItems="center">
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="subtitle2"
          >
            {fetching
              ? t("loading")
              : `${overallCount || 0} ${t("nrCases").toLowerCase()}`}
          </Typography>
          <Button
            variant="secondary"
            onClick={clearFilters}
            id="clear-filters-button"
            disabled={allNull(filters)}
            className={`${classes.clearButton} ${
              showActiveClearFilterButton() && classes.activeButton
            } `}
          >
            <Typography variant="body2">{t("clearFilters")}</Typography>
          </Button>
        </Box>
        <ExportReport
          classes={classes}
          filtersWithOrg={{ ...filters, organizationIds }}
        />
      </Box>

      <div className={classes.caseTable}>
        <CasesTable
          userData={userData}
          cases={cases}
          fetching={fetching}
          overallCount={overallCount}
          onLoadMore={(startIndex, stopIndex) => {
            if (!fetching) {
              fetchNextPageCases(startIndex, stopIndex);
            }
          }}
          minimumBatchSize={pageSize}
        />
      </div>
    </Box>
  );
}

Cases.propTypes = {
  organizationIds: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.shape({
    caseTable: PropTypes.string,
    title: PropTypes.string,
    clearButton: PropTypes.string,
    activeButton: PropTypes.string,
  }),
  pageSize: PropTypes.number,
};

Cases.defaultProps = {
  organizationIds: [],
  classes: {
    caseTable: "",
    title: "",
    clearButton: "",
    activeButton: "",
  },
  pageSize: PAGE_SIZE,
};

export default Cases;
